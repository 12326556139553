



import Vue from 'vue';
import TokensTable from './../../components/tokens/TokensTable.vue'
import {TokenState} from '../../store/tokens';

export default Vue.extend({
  components: {
    TokensTable,
  },
  data: () => ({
    tokenState: TokenState.CertificateCredit,
  })
})
